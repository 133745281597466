<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Merchant Parameters</span>
                <span class="headline" v-else>Create Merchant Parameters</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm8 v-if="formType === 'UPDATE'">
                                <v-text-field
                                    label="ID"
                                    hide-details="auto"
                                    data-cy="form-id"
                                    v-model="form.id"
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Merchant Code*"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-merchant-code"
                                    v-model="form.merchantCode"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Payconnect Reference Map*"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-payconnect-reference-map"
                                    v-model="form.payconnectReferenceMap"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Merchant Reference Map*"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-merchant-reference-map"
                                    v-model="form.merchantReferenceMap"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Amount Map*"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-amount-map"
                                    v-model="form.amountMap"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Transaction Date Map*"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-transaction-date-map"
                                    v-model="form.transactionDateMap"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Transaction Date Format*"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-transaction-date-format"
                                    v-model="form.transactionDateFormat"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Type Map"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-type-map"
                                    v-model="form.typeMap"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Type Condition"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-type-condition"
                                    v-model="form.typeCondition"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Status Map"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-status-map"
                                    v-model="form.statusMap"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Status Condition"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-status-condition"
                                    v-model="form.statusCondition"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="External Reference Map"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-external-reference-map"
                                    v-model="form.externalReferenceMap"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm8>
                                <v-text-field
                                    label="Unmatched Retry Days"
                                    clearable
                                    hide-details="auto"
                                    data-cy="form-unmatched-retry-days"
                                    v-model="form.unmatchedRetryDays"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        merchants: Array
    },
    computed: {
        populatedForm() {
            return {
                id: this.form.id,
                merchantCode: this.form.merchantCode,
                payconnectReferenceMap: this.form.payconnectReferenceMap,
                merchantReferenceMap: this.form.merchantReferenceMap,
                amountMap: this.form.amountMap,
                transactionDateMap: this.form.transactionDateMap,
                transactionDateFormat: this.form.transactionDateFormat,
                typeMap: this.form.typeMap,
                typeCondition: this.form.typeCondition,
                statusMap: this.form.statusMap,
                statusCondition: this.form.statusCondition,
                externalReferenceMap: this.form.externalReferenceMap,
                unmatchedRetryDays: this.form.unmatchedRetryDays,
                name: this.form.name,
                formType: this.formType
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            form: {
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                properEmail: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid. Ex. juandelacruz@apollo.com.ph'
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-merchant-parameters', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    merchantCode: undefined,
                    payconnectReferenceMap: undefined,
                    merchantReferenceMap: undefined,
                    amountMap: undefined,
                    transactionDateMap: undefined,
                    transactionDateFormat: undefined,
                    typeMap: undefined,
                    typeCondition: undefined,
                    statusMap: undefined,
                    statusCondition: undefined,
                    externalReferenceMap: undefined,
                    unmatchedRetryDays: undefined,
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    id: this.selectedItem.id,
                    merchantCode: this.selectedItem.merchantCode,
                    payconnectReferenceMap: this.selectedItem.payconnectReferenceMap,
                    merchantReferenceMap: this.selectedItem.merchantReferenceMap,
                    amountMap: this.selectedItem.amountMap,
                    transactionDateMap: this.selectedItem.transactionDateMap,
                    transactionDateFormat: this.selectedItem.transactionDateFormat,
                    typeMap: this.selectedItem.typeMap,
                    typeCondition: this.selectedItem.typeCondition,
                    statusMap: this.selectedItem.statusMap,
                    statusCondition: this.selectedItem.statusCondition,
                    externalReferenceMap: this.selectedItem.externalReferenceMap,
                    unmatchedRetryDays: this.selectedItem.unmatchedRetryDays,
                }
            }
        },
    }
}
</script>

<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
    <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" data-cy="form-details"/>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Merchant Parameters</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
        <v-row class="ma-1">
            <v-col class="d-flex" cols="12" md="2">
                <v-autocomplete
                    clearable chips
                    :items="merchantCodes"
                    label="Merchant"
                    v-model="filteredTransaction.merchantCode"
                    return-object
                    data-cy="merchant-code-list"
                    @change="searchMerchantCode">
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="ma-2">
            <v-col class="d-flex" cols="12" md="1">
                <v-btn dark medium color=success @click="queryData(true)" class="mt-2" data-cy="action-button-search">
                    <v-icon dark left> mdi-magnify</v-icon>Search
                </v-btn>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_SEARCH_RECON')">
                    <v-btn :block="$vuetify.breakpoint.mdAndDown" class="ml-10 mt-2" color="success" @click.stop="showMerchantParams('CREATED')" data-cy="action-button-create-payment-agent">Create Merchant Parameters</v-btn>
                    <merchant-parameters-form ref="merchantParametersForm" :selected-item="selectedMerchantParams" :is-show="showFormDialog" :form-type="merchantParametersFormType" v-on:close-dialog="closeDialog" v-on:save-merchant-parameters="saveMerchantParams" data-cy="form-merchant-channel"/>
                </v-flex>
            </v-col>
        </v-row>
    </v-card>

    <!-- <div class="text-uppercase text-bold">id selected: {{selected}}</div> -->
    <!-- <div class="col-md-6 text-right">
        <strong>Total Amount:</strong> <span class="price-content">{{totalAmount}}</span>
    </div> -->

    <template>
        <v-card>
            <v-data-table :hide-default-header="true" :headers="headers" :items="displayMatchedTransactions" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMatchedTransactions" :footer-props="{'items-per-page-options': [10]}">
                <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr :colspan="headers.length">
                                <th v-for="header in headers" :key="header.text">
                                    <span class="d-flex justify-center">{{header.text}}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                                <td>
                                    <span class="d-flex justify-center">
                                        <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewTransaction(item)" data-cy="list-action-button-search">
                                            <v-icon dark medium>mdi-magnify-plus</v-icon>
                                        </v-btn>
                                        <v-btn fab dark x-small color="green" @click.stop="showMerchantParams('UPDATE', item)" v-if="hasPermission('ROLE_TDM_SEARCH_RECON')" data-cy="list-action-button-update">
                                            <v-icon dark medium>mdi-pencil</v-icon>
                                        </v-btn>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                            Found no results.
                        </v-alert>
                    </template>
            </v-data-table>
        </v-card>
    </template>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import MerchantParametersForm from '@/views/components/merchantParametersForm.vue'

export default {
    name: 'Unmatched',
    components: {
        Loading,
        DetailsForm,
        MerchantParametersForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
    },
    data() {
        return {
            headers: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Merchant Code',
                    value: 'merchantCode'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            listOfMerchantCodes: [],
            merchantParametersFormType: 'CREATE',
            merchantCodes: this.listOfMerchantCodes ?? [],
            loading: true,
            page: 1,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalMatchedTransactions: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayMatchedTransactions: [],
            remainderMatchedTransactions: [],
            paymentAgents: [],
            selectedMerchantParams: {},
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
            totalCount:'',
        }
    },
    watch: {
        options: {
            handler() {
                this.queryData(false);
                this.setData();
            },
        },
        listOfMerchantCodes: {
            handler(value) {
                this.merchantCodes = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await API.getMerchantCode()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfMerchantCodes = listResponse.merchantCodes
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const listResponse = await API.getAllPaymentAgents()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.paymentAgents = listResponse.paymentAgent
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var matchedTransactionList = undefined

                const parameters = {
                    merchantCode: this.filteredTransaction ? this.filteredTransaction.merchantCode ?? '' : '',
                    pageNo: pageNumber,
                    pageSize: itemsPerPage
                }

                matchedTransactionList = await API.getMerchantParameters(parameters)
                if (!matchedTransactionList || matchedTransactionList.error) {
                    console.log(`${matchedTransactionList.error}`)
                } else {
                    var filteredList = matchedTransactionList.merchants
                    var length = matchedTransactionList.rowCount
                    if (page > 1 && this.remainderMatchedTransactions.length > 0) {
                        filteredList = this.remainderMatchedTransactions.concat(filteredList)
                    }
                    // this.totalMatchedTransactions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    this.totalMatchedTransactions = length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMatchedTransactions = filteredList.slice(0, end)
                    this.remainderMatchedTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMatchedTransactions / itemsPerPage

                    parameters.offset = this.totalMatchedTransactions
                    parameters.limit = 1

                    const hasMoreResponse = matchedTransactionList
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.merchants
                        if(hasMoreList.length > 0) {
                        // this.totalMatchedTransactions = Math.floor(this.totalMatchedTransactions/10) * 10 + 1
                        this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        showMerchantParams(what, item = {}) {
            this.merchantParametersFormType = what
            this.selectedMerchantParams = item
            this.showFormDialog = true
        },
        searchMerchantCode: async function(val) {
            this.filteredTransaction.merchantCode = val
            // this.queryData(true)
        },
        searchMerchantId(val) {
            this.filteredTransaction.merchantId = val
            // this.queryData(true)
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveMerchantParams(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new merchant parameters.`
                    setTimeout(async () => {
                        const createResponse = await API.createMerchantParameters(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                            this.showFormDialog = true
                        } else {
                            this.loadingMessage = `Successfully new merchant parameters.`
                            this.showFormDialog = false
                            this.$refs.merchantParametersForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating merchant parameters.`
                    setTimeout(async () => {
                        const updateResponse = await API.updateMerchantParameters(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                            this.showFormDialog = true
                        } else {
                            this.loadingMessage = `Successfully updated merchant parameters.`
                            this.showFormDialog = false
                            this.$refs.merchantParametersForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.title = transaction.sevenPayID
            this.details.merchantId = transaction.merchant
            this.details.status = transaction.paymentStatus
            this.details.item = transaction
            this.isShowDetails = true
        },
		selectAllatOnce() {
			this.selected = [];
			if (!this.selectAll) {
                 for (let index in this.displayMatchedTransactions) {
                      this.selected.push(this.displayMatchedTransactions[index]);
                }
                //for improvement
			}
            console.log(this.selected);
		},
        selectOne() {
            this.selectAll = false;
        },
        settleTransactions(transactions) {
            // FOLLOW THIS FORMAT!
            // sample transactions [{transaction},{transaction},{transaction}]
        },
    }
};
</script>
